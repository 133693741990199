import { DataTable } from "primereact/datatable";
import { MainLoader, useGlobalTableFilter } from "../../common";
import { useGetStockLedgerQuery } from "../../services/stockTradeApi";
import { Column } from "primereact/column";

function TradeLedgerTable() {
  const { data, isLoading } = useGetStockLedgerQuery();
  const { filters, renderHeader } = useGlobalTableFilter();

  return (
    <>
      {isLoading && <MainLoader />}
      {!isLoading && (
        <div className="card">
          <DataTable
            value={data?.result || []}
            header={renderHeader("Stock Trade Ledger")}
            stripedRows
            showGridlines
            size="small"
            scrollable
            paginator
            rows={27}
            filters={filters}
            emptyMessage="No stock report found."
            globalFilterFields={["equityname", "orderid", "lastupdateddate"]}
          >
            <Column field="orderid" header="Order Id"></Column>
            <Column field="equityname" header="Equity Name"></Column>
            <Column field="lastupdateddate" header="Last Updated"></Column>
            <Column field="quantitypurchased" header="Purchased Qty."></Column>
            <Column field="quantityavailable" header="Holding Qty."></Column>
            <Column
              field="buyavgprice"
              header="Avg. Buy Price"
              body={(rowData) => rowData.buyavgprice?.toFixed(2)}
              bodyStyle={{ textAlign: "right" }}
            ></Column>
            <Column
              field="sellavgprice"
              header="Avg. Sale Price"
              body={(rowData) => rowData.sellavgprice?.toFixed(2)}
              bodyStyle={{ textAlign: "right" }}
            ></Column>
            <Column
              field="totalbuyvalue"
              header="Buy Value"
              body={(rowData) => rowData.totalbuyvalue?.toFixed(2)}
              bodyStyle={{ textAlign: "right" }}
              sortable
            ></Column>
            <Column
              field="totalsellvalue"
              header="Sell Value"
              body={(rowData) => rowData.totalsellvalue?.toFixed(2)}
              bodyStyle={{ textAlign: "right" }}
            ></Column>
            <Column
              field="realizedpl"
              header="Realized P/L"
              body={(rowData) => rowData.realizedpl?.toFixed(2)}
              bodyStyle={{ textAlign: "right" }}
              sortable
            ></Column>
            <Column
              field="capitalchange"
              header="Change"
              body={(rowData) => `${rowData.capitalchange.toFixed(2)}%`}
              bodyStyle={{ textAlign: "right" }}
              sortable
            ></Column>
          </DataTable>
        </div>
      )}
    </>
  );
}

export default TradeLedgerTable;
