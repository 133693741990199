import { useNavigate } from "react-router-dom";
import WelcomeUser from "../components/header/WelcomeUser";
import { StockTradeCards } from "../components/smartcard";
import StockTradingTab from "../data/tabviews/StockTradingTab";
import { useEffect } from "react";

function StockPortfolio() {
  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = localStorage.getItem("kite_access_token");
    const expiryTimestamp = localStorage.getItem("kite_token_expiry");

    if (
      !accessToken ||
      !expiryTimestamp ||
      Date.now() > Number(expiryTimestamp)
    ) {
      localStorage.removeItem("kite_access_token");
      localStorage.removeItem("kite_token_expiry");

      navigate("/stocktrade/kite");
    }
  }, [navigate]);

  return (
    <div className="overview-container">
      <div className="overview-header">
        <WelcomeUser />
      </div>
      <div className="insights-panel">
        <StockTradeCards />
      </div>
      <div className="statements-panel">
        <StockTradingTab />
      </div>
    </div>
  );
}

export default StockPortfolio;
