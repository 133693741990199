import { TabPanel, TabView } from "primereact/tabview";
import { useState } from "react";
import {
  ContractNoteTable,
  TradeBookTable,
  TradeLedgerTable,
  TradeReportTable,
} from "../../data";

const StockTradingTab = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className="card">
      <TabView
        activeIndex={activeIndex}
        onTabChange={(e) => setActiveIndex(e.index)}
      >
        <TabPanel header="Stock Portfolio">
          <TradeReportTable />
        </TabPanel>
        <TabPanel header="Trade Book">
          <TradeBookTable />
        </TabPanel>
        <TabPanel header="Trade Ledger">
          <TradeLedgerTable />
        </TabPanel>
        <TabPanel header="Contract Notes">
          <ContractNoteTable />
        </TabPanel>
      </TabView>
    </div>
  );
};

export default StockTradingTab;
