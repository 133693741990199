import WelcomeUser from "../components/header/WelcomeUser";
import { StockTradeCards } from "../components/smartcard";
import { StockTradeForm } from "../containers";

function AddTradeOrder() {
  return (
    <div className="transact-container">
      <div className="overview-header">
        <WelcomeUser />
      </div>
      <div className="insights-panel">
        <StockTradeCards />
      </div>
      <div className="transact-form-panel">
        <StockTradeForm />
      </div>
      <div></div>
    </div>
  );
}

export default AddTradeOrder;
