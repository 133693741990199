import { TabView, TabPanel } from "primereact/tabview";
import { useGetAccountStatementsQuery } from "../../services/statementsApi";
import { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { MainLoader, useGlobalTableFilter } from "../../common";
import { useSelector } from "react-redux";
import { RootState } from "../../store/redux";
import "../../styles/templates/primereact.css";

function AccountStatements() {
  const accountCodes = useSelector(
    (state: RootState) => state.accountCodeStore.accountcodes
  );

  const [activeIndex, setActiveIndex] = useState(0);
  const [stmtAccount, setStmtAccount] = useState(accountCodes?.[0]);

  const { data: statementData, isFetching } = useGetAccountStatementsQuery(
    stmtAccount ?? "",
    {
      skip: !stmtAccount,
    }
  );

  useEffect(() => {
    if (accountCodes && accountCodes[activeIndex]) {
      setStmtAccount(accountCodes[activeIndex]);
    }
  }, [activeIndex, accountCodes]);

  const { filters, renderHeader } = useGlobalTableFilter();
  return (
    <div>
      <TabView
        activeIndex={activeIndex}
        onTabChange={(e) => setActiveIndex(e.index)}
      >
        {accountCodes?.map((code) => (
          <TabPanel
            header={
              <span style={{ fontSize: "13px", fontWeight: 600 }}>{code}</span>
            }
            key={code}
          >
            {isFetching ? (
              <MainLoader />
            ) : (
              <DataTable
                value={statementData?.result || []}
                header={renderHeader("Transactions")}
                scrollable
                paginator
                rows={25}
                rowsPerPageOptions={[10, 25, 50]}
                filters={filters}
                size="small"
                emptyMessage="No transactions found."
                globalFilterFields={["component", "description", "type"]}
              >
                <Column field="rownum" header="Row Number" />
                <Column field="trn_id" header="Transaction ID" />
                <Column
                  field="date"
                  header="Date"
                  body={(rowData) => rowData.date.slice(0, -9)}
                />
                <Column field="component" header="Component" />
                <Column field="type" header="Type" />
                <Column field="description" header="Description" />
                <Column field="netamount" header="Amount" />
                <Column field="balance" header="Balance" />
              </DataTable>
            )}
          </TabPanel>
        ))}
      </TabView>
    </div>
  );
}

export default AccountStatements;
