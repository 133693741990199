import { DataTable } from "primereact/datatable";
import { MainLoader, useGlobalTableFilter } from "../../common";
import { useGetStockTradeReportQuery } from "../../services/stockTradeApi";
import { Column } from "primereact/column";
import { useGetHoldingsQuery } from "../../services/kiteConnectApi";

function TradeReportTable() {
  const accessToken = localStorage.getItem("kite_access_token") ?? "";

  const { data: reportData, isLoading: isReportLoading } =
    useGetStockTradeReportQuery();

  const { data: holdingsData, isLoading: isHoldingsLoading } =
    useGetHoldingsQuery(accessToken, { skip: !accessToken });

  const { filters, renderHeader } = useGlobalTableFilter();

  const mergeData = reportData?.result.map((stock) => {
    const holding = holdingsData?.find((h) => h.tradingsymbol === stock.symbol);
    return {
      ...stock,
      lastprice: holding ? holding.lastprice : 0,
      pnl: holding ? holding.pnl : 0,
    };
  });

  return (
    <>
      {isReportLoading || (isHoldingsLoading && <MainLoader />)}
      {!isHoldingsLoading && !isReportLoading && (
        <div className="card">
          <DataTable
            value={mergeData || []}
            header={renderHeader("Stock Trading Portfolio")}
            stripedRows
            showGridlines
            size="small"
            scrollable
            paginator
            rows={27}
            filters={filters}
            emptyMessage="No stock report found."
            globalFilterFields={["symbol", "name", "stockid"]}
          >
            <Column field="rownum" header="ID"></Column>
            <Column field="stockid" header="Stock Id"></Column>
            <Column field="symbol" header="Symbol" sortable></Column>
            <Column field="name" header="Company Name"></Column>
            <Column field="quantity" header="Quantity"></Column>
            <Column
              field="avgbuyprice"
              header="Buy Price"
              body={(rowData) => rowData.avgbuyprice?.toFixed(2)}
              bodyStyle={{ textAlign: "right" }}
            ></Column>
            {/* <Column field="buyvalue" header="Buy Value"></Column> */}
            <Column
              field="avgsaleprice"
              header="Sale Price"
              body={(rowData) => rowData.avgsaleprice?.toFixed(2)}
              bodyStyle={{ textAlign: "right" }}
            ></Column>
            <Column
              field="lastprice"
              header="Last Price"
              body={(rowData) => rowData.lastprice?.toFixed(2)}
              bodyStyle={{ textAlign: "right" }}
            />
            {/* <Column field="sellvalue" header="Sell Value"></Column> */}
            <Column
              field="charges"
              header="Total Charges"
              body={(rowData) => rowData.charges?.toFixed(2)}
              bodyStyle={{ textAlign: "right" }}
              sortable
            ></Column>
            <Column
              field="tds"
              header="TDS"
              body={(rowData) => rowData.tds?.toFixed(2)}
              bodyStyle={{ textAlign: "right" }}
            ></Column>
            <Column
              field="pnl"
              header="Unrealized P/L"
              body={(rowData) => rowData.pnl?.toFixed(2)}
              bodyStyle={{ textAlign: "right" }}
            />
            <Column
              field="realizedpl"
              header="Realized P/L"
              body={(rowData) => rowData.realizedpl?.toFixed(2)}
              bodyStyle={{ textAlign: "right" }}
              sortable
            ></Column>
            <Column
              field="netpl"
              header="Net P/L"
              body={(rowData) => rowData.netpl?.toFixed(2)}
              bodyStyle={{ textAlign: "right" }}
              sortable
            ></Column>
            <Column
              field="roi"
              header="ROI"
              body={(rowData) => `${rowData.roi.toFixed(2)}%`}
              bodyStyle={{ textAlign: "right" }}
              sortable
            ></Column>
          </DataTable>
        </div>
      )}
    </>
  );
}

export default TradeReportTable;
