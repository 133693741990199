import { useSelector } from "react-redux";
import { RootState } from "../../store/redux";
import { SmartCardItem } from "../dashboard";
import { useGetStockTradeStatsQuery } from "../../services/stockTradeApi";
import { MainLoader } from "../../common";

const StockTradeCards = () => {
  const storeData = useSelector((state: RootState) => state.stockTradeStore);

  /* Skip will prevent Invalidation of tags
  const shouldFetch = !storeData?.investment;
  useGetStockTradeStatsQuery(undefined, {
    skip: !shouldFetch,
  });
  */

  const { data: stockTradeStats } = useGetStockTradeStatsQuery();
  if (!storeData?.investment || !stockTradeStats) {
    return <MainLoader />;
  }

  const smartCardItems = [
    {
      id: 1,
      title: "INVESTMENT",
      amount: `₹ ${storeData?.investment?.toLocaleString("en-IN", {
        minimumFractionDigits: 2,
      })}`,
      iconClass: "ni ni-money-coins icon-color",
      trend: `₹ ${storeData?.accountbalance?.toLocaleString("en-IN", {
        minimumFractionDigits: 2,
      })} bal`,
      trendStyle:
        storeData?.investment < 0 ? { color: "red" } : { color: "#18c379" },
      trendIcon:
        storeData?.investment < 0 ? "fa fa-arrow-down" : "fa fa-arrow-up",
      iconStyle: "icon-style expense-color",
      iconColor: "",
    },
    {
      id: 2,
      title: "REALIZED P/L",
      amount: `₹ ${storeData?.realized_pl?.toLocaleString("en-IN", {
        minimumFractionDigits: 2,
      })}`,
      iconClass: "ni ni-world icon-color",
      trend: `Charges: ₹ ${storeData?.charges?.toLocaleString("en-IN", {
        minimumFractionDigits: 2,
      })}`,
      trendStyle: { color: "#344767" },
      trendIcon: storeData?.tds < 0 ? "fa fa-arrow-down" : "",
      iconStyle: "icon-style savings-color",
      iconColor: "",
    },
    {
      id: 3,
      title: "TDS",
      amount: `₹ ${storeData?.tds?.toLocaleString("en-IN", {
        minimumFractionDigits: 2,
      })}`,
      trend: `₹ ${storeData?.capitalloss?.toLocaleString("en-IN", {
        maximumFractionDigits: 0,
      })} cap.loss`,
      iconClass: "ni ni-cart icon-color",
      trendStyle:
        storeData?.capitalloss < 0 ? { color: "red" } : { color: "#18c379" },
      trendIcon:
        storeData?.capitalloss < 0 ? "fa fa-arrow-down" : "fa fa-arrow-up",
      iconStyle: "icon-style zakat-color",
      iconColor: "",
    },
    {
      id: 4,
      title: "NET P/L",
      amount: `₹ ${(
        storeData?.net_pl + storeData.unrealized_pl
      )?.toLocaleString("en-IN", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
      iconClass: "ni ni-world icon-color",
      trend: `₹ ${storeData?.unrealized_pl?.toLocaleString("en-IN", {
        maximumFractionDigits: 0,
      })} Unr.`,
      trendStyle:
        storeData?.unrealized_pl < 0 ? { color: "red" } : { color: "#18c379" },
      trendIcon:
        storeData?.unrealized_pl < 0 ? "fa fa-arrow-down" : "fa fa-arrow-up",
      iconStyle: "icon-style income-color",
      iconColor: "",
    },
  ];

  return (
    <div className="smartcard-container">
      {smartCardItems.map((item) => (
        <SmartCardItem
          key={item.id} // important to avoid console warning
          title={item.title}
          dataPoint={item.amount}
          trend={item.trend}
          trendStyle={item.trendStyle}
          trendIcon={item.trendIcon}
          iconClass={item.iconClass}
          iconColor={item.iconColor}
          iconStyle={item.iconStyle}
          width="22%"
        />
      ))}
    </div>
  );
};

export default StockTradeCards;
