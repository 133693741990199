import React from "react";
import { useGetTransactionsByComponentQuery } from "../../services/transactionsApi";
import { MainLoader } from "../../common";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { TransactionComponentFilter } from "../../interfaces";

const ComponentTransactions: React.FC<TransactionComponentFilter> = React.memo(
  ({ item, type }) => {
    console.log("Component Transactions");
    const { data, isLoading } = useGetTransactionsByComponentQuery({
      item,
      type,
    });

    return (
      <>
        {isLoading && <MainLoader />}
        {!isLoading && (
          <div className="card flex-table-w35">
            <DataTable
              value={data?.result || []}
              header="Transactions"
              showGridlines
              size="small"
              scrollable
              scrollHeight="37vh"
              tableStyle={{ minWidth: "35rem" }}
            >
              <Column field="trndate" header="Date"></Column>
              <Column field="account" header="Account Code"></Column>
              <Column field="people" header="People"></Column>
              <Column field="description" header="Description"></Column>
              <Column field="quantity" header="Qty"></Column>
              <Column
                field="netamountinr"
                header="Amount"
                body={(rowData) => `₹ ${rowData.netamountinr}`}
              ></Column>
            </DataTable>
          </div>
        )}
      </>
    );
  }
);

export default ComponentTransactions;
