import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { IHoldings, IKiteLogin, IRequestToken } from "../interfaces";

const kiteConnectApi = createApi({
  reducerPath: "kiteApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_FM_API_BASE_URL,
  }),
  endpoints: (builder) => ({
    getLoginUrl: builder.query<IKiteLogin, void>({
      query: () => "kite/login-url",
    }),

    getHolding: builder.query<IHoldings[], void>({
      query: () => {
        const accessToken = localStorage.getItem("kite_access_token");
        if (!accessToken) {
          throw new Error("Access token is missing");
        }

        return {
          url: "kite/holdings",
          headers: {
            AccessToken: accessToken,
          },
        };
      },
    }),

    getHoldings: builder.query<IHoldings[], string>({
      query: (accessToken) => {
        return {
          url: "kite/holdings",
          headers: {
            AccessToken: accessToken,
          },
        };
      },
    }),

    initSession: builder.mutation({
      query: (requestToken: IRequestToken) => ({
        url: "kite/init-session",
        method: "POST",
        body: requestToken,
        headers: { "Content-Type": "application/json" },
      }),
    }),
  }),
});

export const {
  useGetLoginUrlQuery,
  useGetHoldingsQuery,
  useInitSessionMutation,
} = kiteConnectApi;
export default kiteConnectApi;
