import React, { useEffect, useMemo, useState } from "react";
import { useGetStockHoldingsQuery } from "../services/stockTradeApi";
import { MainLoader } from "../common";
import { SelectFormField } from "../components/formcontrols";
import { DropdownChangeEvent } from "primereact/dropdown";

interface StockViewFormProps {
  onStockIdChange: (stockid: number) => void;
  onPeriodChange: (period: string) => void;
}

interface SelectionState {
  stockid?: number;
  period: string;
}

const StockViewForm: React.FC<StockViewFormProps> = ({
  onStockIdChange,
  onPeriodChange,
}) => {
  const { data, isLoading } = useGetStockHoldingsQuery();

  const equities = useMemo(
    () =>
      data?.result?.map((item: { stockid: number; symbol: string }) => ({
        value: item.stockid.toString(),
        label: item.symbol,
      })) || [],
    [data?.result]
  );

  const [selection, setSelection] = useState<SelectionState>({
    stockid: undefined,
    period: "Year to Date",
  });

  useEffect(() => {
    if (equities.length > 0 && selection.stockid === undefined) {
      const firstStockId = Number(equities[0].value);
      setSelection((prev) => ({
        ...prev,
        stockid: firstStockId,
      }));

      onStockIdChange(firstStockId);
    }
  }, [equities, onStockIdChange, selection.stockid]);

  const handleStockIdSelectChange = (e: DropdownChangeEvent) => {
    const stockid = Number(e.value);
    setSelection((prev) => ({ ...prev, stockid }));
    onStockIdChange(stockid);
  };

  const handlePeriodSelectChange = (e: DropdownChangeEvent) => {
    const period = e.target.value;
    setSelection((prev) => ({ ...prev, period }));
    onPeriodChange(period);
  };

  return (
    <>
      {isLoading && <MainLoader />}
      {!isLoading && (
        <div className="">
          <h5>SELECT STOCK</h5>
          <form className="transact-form">
            <ul>
              <li>
                <SelectFormField
                  label="Symbol"
                  name="stockid"
                  value={selection.stockid?.toString() ?? null}
                  options={equities}
                  optionLabel="label"
                  onChange={handleStockIdSelectChange}
                  editable={false}
                />
              </li>
              <li>
                <SelectFormField
                  label="Period"
                  name="period"
                  value={selection.period}
                  options={[
                    "Last 7 days",
                    "Last 2 weeks",
                    "Last 1 month",
                    "Last 3 months",
                    "Last 6 months",
                    "Last 1 year",
                    "Last 5 years",
                    "Year to Date",
                  ]}
                  optionLabel="period"
                  onChange={handlePeriodSelectChange}
                />
              </li>
            </ul>
          </form>
          <br />
        </div>
      )}
    </>
  );
};

export default StockViewForm;
