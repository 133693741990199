import { createSlice } from "@reduxjs/toolkit";
import { stockTradeApi, kiteConnectApi } from "../../services";

interface StockTradeState {
  unrealized_pl: number;
  realized_pl: number;
  net_pl: number;
  charges: number;
  tds: number;
  investment: number;
  accountbalance: number;
  capitalloss: number;
  shouldFetchStats: boolean;
}

const initialState: StockTradeState = {
  unrealized_pl: 0,
  realized_pl: 0,
  net_pl: 0,
  charges: 0,
  tds: 0,
  investment: 0,
  accountbalance: 0,
  capitalloss: 0,
  shouldFetchStats: true,
};

export const stockTradeSlice = createSlice({
  name: "stocktrade",
  initialState: initialState,
  reducers: {
    /*
    setShouldFetchStats: (state, action) => {
      state.shouldFetchStats = action.payload;
    },
    */
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        stockTradeApi.endpoints.getStockTradeReport.matchFulfilled,
        (state, action) => {
          const tradeReport = action.payload.result;

          state.realized_pl = tradeReport.reduce(
            (sum, item) => sum + item.realizedpl,
            0
          );
          state.net_pl = tradeReport.reduce((sum, item) => sum + item.netpl, 0);
          state.charges = tradeReport.reduce(
            (sum, item) => sum + item.charges,
            0
          );
          state.tds = tradeReport.reduce((sum, item) => sum + item.tds, 0);

          // state.shouldFetchStats = false; // Reset after fetching stats
          // removing local storage as redux persist already does this
          // localStorage.setItem("stockTradeReport", JSON.stringify(state));
        }
      )
      .addMatcher(
        stockTradeApi.endpoints.getStockTradeStats.matchFulfilled,
        (state, action) => {
          state.investment = action.payload.investment;
          state.accountbalance = action.payload.accountbalance;
          state.capitalloss = action.payload.capitalloss;
        }
      )
      .addMatcher(
        kiteConnectApi.endpoints.getHoldings.matchFulfilled,
        (state, action) => {
          state.unrealized_pl = action.payload.reduce(
            (sum, holding) => sum + (holding.pnl || 0),
            0
          );
        }
      )
      .addMatcher(
        stockTradeApi.endpoints.addTradeOrder.matchFulfilled,
        (state) => {
          // state.shouldFetchStats = true;
        }
      );
  },
});

//export const { setShouldFetchStats } = stockTradeSlice.actions;
export const stockTradeReducer = stockTradeSlice.reducer;
