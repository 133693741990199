import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  useGetLoginUrlQuery,
  useInitSessionMutation,
} from "../services/kiteConnectApi";

function KiteConnect() {
  const { data, isLoading } = useGetLoginUrlQuery();
  const [initSession] = useInitSessionMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoading || !data) return; // Wait until data is available

    const existingAccessToken = localStorage.getItem("kite_access_token");
    if (existingAccessToken) {
      navigate("/stocktrade/portfolio");
      return; // ✅ Early return to prevent further execution
    }

    // ✅ Extract request_token from URL before hash (#)
    const urlWithoutHash = window.location.href.split("#")[0];
    const requestToken = new URLSearchParams(
      new URL(urlWithoutHash).search
    ).get("request_token");

    if (requestToken) {
      const expiryTimestamp =
        new Date().setHours(0, 0, 0, 0) + 24 * 60 * 60 * 1000;
      localStorage.setItem("kite_request_token", requestToken);
      window.history.replaceState({}, document.title, "/#/stocktrade/kite");

      initSession({ requestToken })
        .unwrap()
        .then((response) => {
          localStorage.setItem("kite_access_token", response.accesstoken);
          localStorage.setItem("kite_token_expiry", expiryTimestamp.toString());

          navigate("/stocktrade/portfolio");
        })
        .catch((err) => console.error("Session Initialization Failed", err));

      return;
    } else if (data?.loginurl) {
      window.location.href = data.loginurl;
    }
  }, [data, isLoading, initSession, navigate]);

  return null; // ✅ No need to return UI, as it always redirects
}

export default KiteConnect;
