import { useState } from "react";
import WelcomeUser from "../components/header/WelcomeUser";
import { StockTradeCards } from "../components/smartcard";
import { StockViewForm } from "../containers";
import { StockTrendChart } from "../data";
import { StockTrendFilter } from "../interfaces";
import { getFromDateFromPeriod } from "../utility";

function StockTrendView() {
  const [criteria, setCriteria] = useState<StockTrendFilter>({
    stockid: 0,
    fromdate: new Date(new Date().getFullYear(), 0, 1)
      .toISOString()
      .split("T")[0],
    enddate: new Date().toISOString().split("T")[0],
  });

  const handleStockIdChange = (stockid: number) => {
    setCriteria((prev) => ({
      ...prev,
      stockid: Number(stockid),
    }));
  };

  const handlePeriodChange = (period: string) => {
    setCriteria((prev) => ({
      ...prev,
      fromdate: getFromDateFromPeriod(period).toISOString().split("T")[0],
      todate: new Date().toISOString().split("T")[0],
    }));
  };

  return (
    <div className="overview-container">
      <div className="overview-header">
        <WelcomeUser />
      </div>
      <div className="insights-panel">
        <StockTradeCards />
      </div>
      <div className="statements-panel">
        <StockViewForm
          onStockIdChange={handleStockIdChange}
          onPeriodChange={handlePeriodChange}
        />
        <StockTrendChart
          stockid={criteria.stockid}
          fromdate={criteria.fromdate}
          enddate={criteria.enddate}
        />
      </div>
    </div>
  );
}

export default StockTrendView;
