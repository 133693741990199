import {
  DataTable,
  DataTableFilterMeta,
  DataTableRowEditCompleteEvent,
} from "primereact/datatable";
import { Column, ColumnEditorOptions } from "primereact/column";
import { MainLoader } from "../../common";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { getCurrencySymbolFromCode } from "../../utility";

import {
  useEditTransactionRecordMutation,
  useGetAccountTransactionsQuery,
} from "../../services/transactionsApi";
import {
  ApiResponse,
  IRecentTransaction,
  ITransaction,
  ITransactionForm,
} from "../../interfaces";
import { useEffect, useState } from "react";

import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { useSelector } from "react-redux";
import { RootState } from "../../store/redux";
import { TabPanel, TabView } from "primereact/tabview";
import { toastNotify } from "../../utility";

function AccountTransactions() {
  const accountCodes = useSelector(
    (state: RootState) => state.accountCodeStore.accountcodes
  );

  const [activeIndex, setActiveIndex] = useState(0);
  const [trnsAccount, setTrnsAccount] = useState(accountCodes?.[0]);

  const { data, isFetching } = useGetAccountTransactionsQuery(
    trnsAccount ?? "",
    { skip: !trnsAccount }
  );
  const [transactions, setTransactions] = useState<ITransactionForm[]>();

  const [updateTransaction] = useEditTransactionRecordMutation();

  useEffect(() => {
    if (data) {
      setTransactions(data.result);
    }
  }, [data]);

  useEffect(() => {
    if (accountCodes && accountCodes[activeIndex]) {
      setTrnsAccount(accountCodes[activeIndex]);
    }
  }, [activeIndex, accountCodes]);

  const onRowEditTransaction = async (e: DataTableRowEditCompleteEvent) => {
    let { newData, index } = e;
    if (transactions && transactions[index]) {
      const updatedTransactions = [...transactions];
      updatedTransactions[index] = newData as ITransactionForm;
      setTransactions(updatedTransactions);
    }

    try {
      const response: ApiResponse = await updateTransaction(
        newData as ITransaction
      ).unwrap();

      if (response) {
        toastNotify("Transaction Record updated !");
      }
    } catch (error) {
      toastNotify("Error: Edit Transaction !", "error");
    }
  };

  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    trnDate: { value: null, matchMode: FilterMatchMode.CONTAINS },
    description: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });

  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filters };
    // @ts-ignore
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="search-box">
        <div>&nbsp;&nbsp;Edit Transaction</div>
        <div>
          <i className="fa fa-search search-icon"></i>
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </div>
      </div>
    );
  };

  const allowEdit = (rowData: IRecentTransaction) => {
    return rowData.account !== "Blue Band";
  };

  const textEditor = (options: ColumnEditorOptions) => {
    return (
      <InputText
        type="text"
        size={15}
        value={options.value}
        className="p-inputtext-sm"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          options.editorCallback!(e.target.value)
        }
        style={{ width: "100%" }}
      />
    );
  };

  const header = renderHeader();
  return (
    <div>
      <TabView
        activeIndex={activeIndex}
        onTabChange={(e) => setActiveIndex(e.index)}
      >
        {accountCodes?.map((code) => (
          <TabPanel
            key={code}
            header={
              <span style={{ fontSize: "13px", fontWeight: 600 }}>{code}</span>
            }
          >
            {isFetching ? (
              <MainLoader />
            ) : (
              <div className="card">
                <DataTable
                  value={transactions}
                  header={header}
                  editMode="row"
                  onRowEditComplete={onRowEditTransaction}
                  scrollable
                  paginator
                  rows={15}
                  rowsPerPageOptions={[10, 15, 50]}
                  filters={filters}
                  showGridlines
                  size="small"
                  emptyMessage="No transactions found."
                  globalFilterFields={[
                    "account",
                    "description",
                    "type",
                    "date",
                  ]}
                >
                  <Column
                    field="trnid"
                    header="ID"
                    style={{ width: "5%" }}
                    bodyStyle={{ textAlign: "center" }}
                  ></Column>
                  <Column
                    field="trndate"
                    header="Trn Date"
                    editor={(options) => textEditor(options)}
                    style={{ width: "6%" }}
                    bodyStyle={{ textAlign: "center" }}
                    filter
                  ></Column>
                  <Column
                    field="type"
                    header="Type"
                    editor={(options) => textEditor(options)}
                    style={{ width: "5%" }}
                    bodyStyle={{ textAlign: "center" }}
                  ></Column>
                  <Column
                    field="mode"
                    header="Mode"
                    editor={(options) => textEditor(options)}
                    style={{ width: "6%" }}
                  ></Column>
                  <Column
                    field="component"
                    header="Component"
                    editor={(options) => textEditor(options)}
                    style={{ width: "10%" }}
                  ></Column>
                  <Column
                    field="people"
                    header="Contributor"
                    editor={(options) => textEditor(options)}
                    style={{ width: "8%" }}
                  ></Column>
                  <Column
                    field="trngroupid"
                    header="Trn Group"
                    editor={(options) => textEditor(options)}
                    style={{ width: "10%" }}
                    bodyStyle={{ textAlign: "center" }}
                  ></Column>
                  <Column
                    field="description"
                    header="Description"
                    filter
                    editor={(options) => textEditor(options)}
                    style={{ width: "15%" }}
                  ></Column>
                  <Column
                    field="quantity"
                    header="Qty"
                    editor={(options) => textEditor(options)}
                    style={{ width: "4%" }}
                    bodyStyle={{ textAlign: "center" }}
                  ></Column>
                  <Column
                    field="currencycode"
                    header="Currency"
                    editor={(options) => textEditor(options)}
                    style={{ width: "4%" }}
                    bodyStyle={{ textAlign: "center" }}
                  ></Column>
                  <Column
                    field="exchangerate"
                    header="Xchg Rate"
                    editor={(options) => textEditor(options)}
                    style={{ width: "5%" }}
                  ></Column>
                  <Column
                    field="amount"
                    header="Amount"
                    body={(rowData: ITransaction) =>
                      `${getCurrencySymbolFromCode(
                        rowData.currencycode
                      )} ${rowData.amount.toLocaleString()}`
                    }
                    editor={(options) => textEditor(options)}
                    style={{ width: "5%" }}
                  ></Column>
                  <Column
                    field="xtracharge"
                    header="Extra"
                    editor={(options) => textEditor(options)}
                    style={{ width: "5%" }}
                    bodyStyle={{ textAlign: "center" }}
                  ></Column>
                  <Column
                    field="effectivedate"
                    header="Eff. Date"
                    editor={(options) => textEditor(options)}
                    style={{ width: "6%" }}
                    bodyStyle={{ textAlign: "center" }}
                  ></Column>
                  <Column
                    header="Edit"
                    style={{ width: "6%" }}
                    rowEditor={allowEdit}
                    headerStyle={{ minWidth: "5rem" }}
                    bodyStyle={{ textAlign: "center", color: "blue" }}
                  ></Column>
                </DataTable>
              </div>
            )}
          </TabPanel>
        ))}
      </TabView>
    </div>
  );
}

export default AccountTransactions;
