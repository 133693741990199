import { useGetCommonTransactionsQuery } from "../../services/transactionsApi";
import { ICommonTransaction } from "../../interfaces";

import { MainLoader, useGlobalTableFilter } from "../../common";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import "primereact/resources/themes/lara-light-cyan/theme.css";

interface CommonTransactionsProps {
  onClickTransaction: (transaction: ICommonTransaction) => void;
}

function CommonTransactions({ onClickTransaction }: CommonTransactionsProps) {
  const { data, isLoading } = useGetCommonTransactionsQuery();
  const { filters, renderHeader } = useGlobalTableFilter();
  return (
    <>
      {isLoading && <MainLoader />}
      {!isLoading && (
        <div className="card">
          <DataTable
            value={data?.result || []}
            header={renderHeader("Common Transactions")}
            paginator
            rows={20}
            rowsPerPageOptions={[15, 20, 50]}
            filters={filters}
            size="small"
            tableStyle={{ minWidth: "50rem" }}
            scrollable
            scrollHeight="40vh"
            emptyMessage="No transactions found."
            globalFilterFields={["account", "description", "type", "component"]}
            selectionMode="single"
            onSelectionChange={(e) => {
              onClickTransaction(e.value);
            }}
            dataKey="sequentialId"
          >
            <Column field="sequentialId" header="ID"></Column>
            <Column field="account" header="Account Code"></Column>
            <Column field="component" header="Component"></Column>
            <Column field="classifier" header="Classifier"></Column>
            <Column field="type" header="Type"></Column>
            <Column field="people" header="People"></Column>
            <Column field="description" header="Description"></Column>
            <Column field="state" header="State"></Column>
            <Column field="mode" header="Mode"></Column>
            <Column field="total" header="Total"></Column>
          </DataTable>
        </div>
      )}
    </>
  );
}

export default CommonTransactions;
