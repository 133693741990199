import React, { useState, useEffect } from "react";
import { useGetStockPriceTrendsQuery } from "../../services/stockTradeApi";
import { IStockTrendRequest } from "../../interfaces";
import { Chart } from "primereact/chart";

const StockTrendChart: React.FC<IStockTrendRequest> = (props) => {
  const { data, isLoading } = useGetStockPriceTrendsQuery(props);

  const [trendChartData, setTrendChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    if (data) {
      const labels = data.result.map((item) => item.tradedate);
      const low = data.result.map((item) => item.low);
      const high = data.result.map((item) => item.high);
      const price = data.result.map((item) => item.close);

      const stockTrend = {
        labels: labels,
        datasets: [
          {
            label: "Low",
            data: low,
            borderColor: "#66BB6A",
            fill: false,
            tension: 0,
            pointRadius: 1,
          },
          {
            label: "Price",
            data: price,
            borderColor: "#FF6384",
            fill: false,
            tension: 0,
            pointRadius: 1,
          },
          {
            label: "High",
            data: high,
            borderColor: "#9C27B0",
            fill: false,
            tension: 0,
            pointRadius: 1,
          },
        ],
      };

      const options = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          title: {
            display: true,
            text: "STOCK TRENDS VIEW",
            font: {
              size: 14,
            },
            padding: {
              top: 20,
              bottom: 20,
            },
          },
          legend: {
            position: "bottom",
          },
          tooltip: {
            enabled: true, // Enables tooltip on hover
            label: (tooltipItem: any) => {
              const datasetLabel = tooltipItem.dataset.label || "Value";
              const value = tooltipItem.raw;
              return `${datasetLabel}: ${value}`; // Shows the dataset label + value
            },
          },
        },
        interaction: {
          mode: "index", // Show tooltip when hovering over a point
          intersect: false,
        },
        scales: {
          x: {
            title: {
              display: false,
              text: "Year-Month",
            },
            grid: {
              display: false,
            },
          },
          y: {
            title: {
              display: true,
              text: "Share Price",
            },
            grid: {
              display: true,
            },
            ticks: {
              display: true,
            },
          },
        },
        layout: {
          padding: {
            top: 20,
            right: 30,
            bottom: 30,
            left: 20,
          },
        },
      };

      setTrendChartData(stockTrend);
      setChartOptions(options);
    }
  }, [data]);

  return (
    <>
      {/* isLoading && <MainLoader /> */}
      {!isLoading && (
        <>
          <div
            style={{
              width: "100%",
              backgroundColor: "#fff",
              borderRadius: "20px",
            }}
          >
            <Chart
              type="line"
              data={trendChartData}
              options={chartOptions}
              style={{ height: "50vh" }}
            />
          </div>
        </>
      )}
    </>
  );
};

export default StockTrendChart;
