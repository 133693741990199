import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  GenericResponse,
  IStockTradeView,
  ITradeBook,
  IStockLedger,
  ITradeOrder,
  IStockTradingStats,
  IContractNote,
  IStockTrendRequest,
  IStockPriceTrend,
} from "../interfaces";
import { Tags } from "../store/tags";

const stockTradeApi = createApi({
  reducerPath: "stockTradeApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_FM_API_BASE_URL,
  }),
  tagTypes: [Tags.TradingView],
  endpoints: (builder) => ({
    getStockSymbols: builder.query<string[], void>({
      query: () => "stocktrade/symbol",
      transformResponse: (response: GenericResponse<string[]>) =>
        response.result,
    }),

    getStockTradeReport: builder.query<
      GenericResponse<IStockTradeView[]>,
      void
    >({
      query: () => "stocktrade/report",
      providesTags: [Tags.TradingView],
    }),

    getStockTradeBook: builder.query<GenericResponse<ITradeBook[]>, void>({
      query: () => "stocktrade/tradebook",
      providesTags: [Tags.TradingView],
    }),

    getContractNotes: builder.query<GenericResponse<IContractNote[]>, void>({
      query: () => "stocktrade/contractnote",
      providesTags: [Tags.TradingView],
    }),

    getStockLedger: builder.query<GenericResponse<IStockLedger[]>, void>({
      query: () => "stocktrade/ledger",
      providesTags: [Tags.TradingView],
    }),

    getStockHoldings: builder.query<GenericResponse<IStockLedger[]>, void>({
      query: () => "stocktrade/holdings",
      providesTags: [Tags.TradingView],
    }),

    getStockTradeStats: builder.query<IStockTradingStats, void>({
      query: () => "stocktrade/stats",
      providesTags: [Tags.TradingView],
    }),

    getStockPriceTrends: builder.query<
      GenericResponse<IStockPriceTrend[]>,
      IStockTrendRequest
    >({
      query: (stockTrendDto) => ({
        url: "stocktrade/stocktrend",
        params: {
          stockid: stockTrendDto.stockid,
          fromdate: stockTrendDto.fromdate,
          todate: stockTrendDto.enddate,
        },
      }),
    }),

    addTradeOrder: builder.mutation({
      query: (traderOrderData: ITradeOrder) => ({
        url: "stocktrade/tradebook",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: traderOrderData,
      }),
      invalidatesTags: [Tags.TradingView],
    }),
  }),
});

export const {
  useGetStockSymbolsQuery,
  useGetStockTradeReportQuery,
  useGetStockTradeBookQuery,
  useGetContractNotesQuery,
  useGetStockLedgerQuery,
  useGetStockHoldingsQuery,
  useGetStockTradeStatsQuery,
  useGetStockPriceTrendsQuery,
  useAddTradeOrderMutation,
} = stockTradeApi;
export default stockTradeApi;
