import { DataTable } from "primereact/datatable";
import { MainLoader, useGlobalTableFilter } from "../../common";
import { useGetContractNotesQuery } from "../../services/stockTradeApi";
import { Column } from "primereact/column";

function ContractNoteTable() {
  const { data, isLoading } = useGetContractNotesQuery();
  const { filters, renderHeader } = useGlobalTableFilter();
  return (
    <>
      {isLoading && <MainLoader />}
      {!isLoading && (
        <div className="card">
          <DataTable
            value={data?.result || []}
            header={renderHeader("Contract Notes")}
            tableStyle={{ maxWidth: "100%" }}
            stripedRows
            showGridlines
            size="small"
            paginator
            rows={27}
            filters={filters}
            emptyMessage="No contract note found."
            globalFilterFields={["orderdate"]}
          >
            <Column field="orderdate" header="Order Date" sortable></Column>
            <Column
              field="nettradeval"
              header="Net Traded Value"
              body={(rowData) => rowData.nettradeval?.toFixed(2)}
              bodyStyle={{ width: "10%", textAlign: "right" }}
            ></Column>
            <Column
              field="brokerage"
              header="Brokerage"
              bodyStyle={{ textAlign: "center" }}
            ></Column>
            <Column field="exchangefee" header="Exchange Fee"></Column>
            <Column field="cgst" header="CGST"></Column>
            <Column field="sgst" header="SGST"></Column>
            <Column field="stt" header="STT"></Column>
            <Column
              field="sebiturnoverfee"
              header="SEBI Turnover"
              body={(rowData) => rowData.sebiturnoverfee?.toFixed(2)}
              bodyStyle={{ textAlign: "center" }}
            ></Column>
            <Column
              field="stampduty"
              header="Stamp Duty"
              body={(rowData) => rowData.stampduty?.toFixed(2)}
              bodyStyle={{ textAlign: "left" }}
            ></Column>
            <Column
              field="totalcharges"
              header="Total Charges"
              body={(rowData) => rowData.totalcharges?.toFixed(2)}
              bodyStyle={{ textAlign: "right" }}
              sortable
            ></Column>
            <Column
              field="netamount"
              header="Net Amount"
              body={(rowData) => rowData.netamount?.toFixed(2)}
              bodyStyle={{ textAlign: "right" }}
              sortable
            ></Column>
          </DataTable>
        </div>
      )}
    </>
  );
}

export default ContractNoteTable;
