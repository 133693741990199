import { ICommonTransfer } from "../../interfaces";
import { useGetCommonTransfersQuery } from "../../services/transfersApi";
import { DataTable } from "primereact/datatable";
import { MainLoader, useGlobalTableFilter } from "../../common";
import { Column } from "primereact/column";

interface CommonTransfersProps {
  onClickTransfer: (transfer: ICommonTransfer) => void;
}

function CommonTransfers({ onClickTransfer }: CommonTransfersProps) {
  const { data, isLoading } = useGetCommonTransfersQuery();
  const { filters, renderHeader } = useGlobalTableFilter();
  return (
    <>
      {isLoading && <MainLoader />}
      {!isLoading && (
        <div className="card">
          <DataTable
            value={data?.result || []}
            header={renderHeader("Common Transfers")}
            paginator
            rows={25}
            rowsPerPageOptions={[10, 25, 50]}
            filters={filters}
            size="small"
            tableStyle={{ minWidth: "50rem" }}
            scrollable
            scrollHeight="40vh"
            emptyMessage="No transfers found."
            globalFilterFields={["sourceaccount", "targetaccount", "amount"]}
            selectionMode="single"
            onSelectionChange={(e) => {
              onClickTransfer(e.value);
            }}
            dataKey="sequentialId"
          >
            <Column field="sequentialId" header="ID"></Column>
            <Column field="sourceaccount" header="Source Account"></Column>
            <Column field="targetaccount" header="Target Account"></Column>
            <Column field="component" header="Component"></Column>
            <Column field="description" header="Description"></Column>
            <Column field="mode" header="Mode"></Column>
            <Column field="amount" header="Amount"></Column>
            <Column field="servicecharge" header="Service Charge"></Column>
          </DataTable>
        </div>
      )}
    </>
  );
}

export default CommonTransfers;
