import React, { ChangeEvent, useState } from "react";
import { useGetAllAccountQuery } from "../services/accountsApi";
import {
  useAddTradeOrderMutation,
  useGetStockSymbolsQuery,
} from "../services/stockTradeApi";
import { InputFormField, SelectFormField } from "../components/formcontrols";
import { ApiResponse, ITradeOrder } from "../interfaces";
import { DropdownChangeEvent } from "primereact/dropdown";
import { MainLoader } from "../common";
import { toastNotify } from "../utility";

function StockTradeForm() {
  const { data: accountCodes } = useGetAllAccountQuery();
  const accounts = accountCodes?.result.map((acc) => acc.name);

  const { data: equitySymbol, isLoading } = useGetStockSymbolsQuery();
  const [addTradeOrder] = useAddTradeOrderMutation();

  const [tradeOrder, setTradeOrder] = useState<ITradeOrder | null>(() => {
    return {
      orderdate: new Date().toISOString().split("T")[0],
      quantity: 0,
      shareprice: 0.0,
      exchange: "NSE",
      assettype: "EQ",
      ordertype: "BUY",
      accountname: "ZERODHA NON-PIS",
    } as ITradeOrder;
  });

  const handleSelectChange = (e: DropdownChangeEvent) => {
    setTradeOrder(
      (prev) =>
        ({
          ...prev,
          [e.target.name]: e.target.value,
        } as ITradeOrder)
    );
  };

  const handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setTradeOrder((prev) => {
      return {
        ...prev,
        [name]: value,
      } as ITradeOrder;
    });
  };

  const handleRecordTrade = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (tradeOrder) {
        const response: ApiResponse = await addTradeOrder(tradeOrder);

        if (
          response.data?.issuccess &&
          parseInt(response.data?.result?.returnvalue) >= 0
        ) {
          toastNotify("Trade Record added !");
        } else if (response.error) {
          toastNotify(response.error.data.errormessages[0], "error");
        }
      }
    } catch (error) {
      toastNotify("Tradebook add error", "error");
    }
  };

  return (
    <>
      {isLoading && (
        <div className="loader-wrapper">
          <MainLoader />
        </div>
      )}
      <form
        className="transact-form"
        method="POST"
        onSubmit={handleRecordTrade}
      >
        <h3>Add Trade Order</h3>
        <div>
          <ul>
            <li>
              <SelectFormField
                label="Symbol"
                name="stocksymbol"
                value={tradeOrder?.stocksymbol}
                options={equitySymbol || []}
                optionLabel="stocksymbol"
                onChange={handleSelectChange}
              />
            </li>
            <li>
              <SelectFormField
                label="Account"
                name="accountname"
                value={tradeOrder?.accountname}
                options={accounts || []}
                optionLabel="accountname"
                onChange={handleSelectChange}
              />
            </li>
            <li>
              <InputFormField
                label="Order Date"
                name="orderdate"
                value={tradeOrder?.orderdate.toString()}
                onChange={handleTextChange}
              />
            </li>
            <li>
              <InputFormField
                label="Quantity"
                name="quantity"
                value={tradeOrder?.quantity.toString()}
                onChange={handleTextChange}
              />
            </li>
          </ul>
          <ul>
            <li>
              <SelectFormField
                label="Order Type"
                name="ordertype"
                value={tradeOrder?.ordertype}
                options={["BUY", "SELL"]}
                optionLabel="ordertype"
                onChange={handleSelectChange}
              />
            </li>
            <li>
              <SelectFormField
                label="Asset Type"
                name="assettype"
                value={tradeOrder?.assettype}
                options={["EQ", "IPO", "MF"]}
                optionLabel="assettype"
                onChange={handleSelectChange}
              />
            </li>
            <li>
              <InputFormField
                label="Exchange"
                name="exchange"
                value={tradeOrder?.exchange}
                onChange={handleTextChange}
              />
            </li>
            <li>
              <InputFormField
                label="Share Price"
                name="shareprice"
                value={tradeOrder?.shareprice.toString()}
                onChange={handleTextChange}
              />
            </li>
          </ul>
        </div>
        <button className="transact-form-button" type="submit">
          Record Trade
        </button>
      </form>
    </>
  );
}

export default StockTradeForm;
