import { DataTable } from "primereact/datatable";
import { MainLoader, useGlobalTableFilter } from "../../common";
import { useGetStockTradeBookQuery } from "../../services/stockTradeApi";
import { Column } from "primereact/column";

function TradeBookTable() {
  const { data, isLoading } = useGetStockTradeBookQuery();
  const { filters, renderHeader } = useGlobalTableFilter();
  return (
    <>
      {isLoading && <MainLoader />}
      {!isLoading && (
        <div className="card">
          <DataTable
            value={data?.result || []}
            header={renderHeader("Stock Tradebook")}
            stripedRows
            showGridlines
            size="small"
            paginator
            rows={27}
            filters={filters}
            emptyMessage="No stock report found."
            globalFilterFields={["equitysymbol", "ordertype", "orderdate"]}
          >
            <Column field="orderid" header="Order Id"></Column>
            <Column field="stockexchange" header="Exchange"></Column>
            <Column field="equitysymbol" header="Stock"></Column>
            <Column field="ordertype" header="Type" sortable></Column>
            <Column field="orderdate" header="Order Date"></Column>
            <Column field="quantity" header="Quantity"></Column>
            <Column
              field="shareprice"
              header="Share Price"
              body={(rowData) => rowData.shareprice?.toFixed(2)}
              bodyStyle={{ textAlign: "right" }}
            ></Column>
            <Column field="brokerage" header="Brokerage"></Column>
            <Column field="stt" header="STT"></Column>
            <Column
              field="exchangefee"
              header="Exchange Fee"
              body={(rowData) => rowData.exchangefee?.toFixed(2)}
              bodyStyle={{ textAlign: "right" }}
            ></Column>
            <Column
              field="cgst"
              header="CGST"
              body={(rowData) => rowData.cgst?.toFixed(2)}
              bodyStyle={{ textAlign: "right" }}
            ></Column>
            <Column
              field="sgst"
              header="SGST"
              body={(rowData) => rowData.sgst?.toFixed(2)}
              bodyStyle={{ textAlign: "right" }}
            ></Column>
            <Column
              field="sebiturnoverfee"
              header="SEBI Turnover"
              body={(rowData) => rowData.sebiturnoverfee?.toFixed(2)}
              bodyStyle={{ textAlign: "right" }}
              sortable
            ></Column>
            <Column
              field="dp"
              header="DP Charges"
              body={(rowData) => rowData.dp?.toFixed(2)}
              bodyStyle={{ textAlign: "right" }}
              sortable
            ></Column>
            <Column
              field="stampduty"
              header="Stamp Duty"
              body={(rowData) => rowData.stampduty?.toFixed(2)}
              bodyStyle={{ textAlign: "right" }}
              sortable
            ></Column>
            <Column
              field="totalcharges"
              header="Total Charges"
              body={(rowData) => rowData.totalcharges?.toFixed(2)}
              bodyStyle={{ textAlign: "right" }}
              sortable
            ></Column>
            <Column
              field="tds"
              header="TDS"
              body={(rowData) => rowData.tds?.toFixed(2)}
              bodyStyle={{ textAlign: "right" }}
            ></Column>

            <Column
              field="totalamount"
              header="Total Amount"
              body={(rowData) => rowData.totalamount?.toFixed(2)}
              bodyStyle={{ textAlign: "right" }}
              sortable
            ></Column>
          </DataTable>
        </div>
      )}
    </>
  );
}

export default TradeBookTable;
